import * as wjGrid from '@mescius/wijmo.grid';
import store from '@/store';
import { AutoComplete } from '@mescius/wijmo.input';
import { KEYS_CODE } from '@/constants/keyboard'
import {
  BLANK_ID,
  MESSAGE_NOT_NULL,
  MESSAGE_MAX_20_CHARACTOR,
  MESSAGE_IS_NUMBER,
  MESSAGE_INCORRECT_YEAR,
  ORGANIZATIONAL_DIVISION
} from '@/constants/registerData';
import { prepareMasterOrCustomizeDB, getListDbAddNew } from '@/concerns/utils/master-or-customize-db'
import { validateMaximumCharactorOnlyFacility, validateInputIsYearGHG } from '@/utils/validate';
import debounce from 'lodash/debounce'
import { formatValue, $_helper_isNumberType, math, formatBigNumber, formatEmission25Number,validateNumberFieldBySetting, $_helper_isNumberType_bySetting, formatNumberBySetting } from './wijmo.helper'
import { handlerFilterData, handlerAddFilterData, addFilterByPasted, getColumnIndexByBinding, getBranchId, checkFilterData } from '@/concerns/utils/filter-data'
import { addThoundSandComma, formatNumberRealNum } from '@/utils/convertNumber';
import i18n from '@/lang/i18n.js';
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import { getWidthOfAttachedButton, getWidthOfSearchButton } from '@/utils/multiLanguage';
const NA_VALUE = 'N/A';
const patternUrl = '/pattern-s3-c15/4';
export const blankData = (clientRowId) => {
  return {
    clientRowId: clientRowId,
    id: BLANK_ID,
    organizational_division: null,
    business_name: null,
    company_name: null,
    ides_industry: null,
    ides_name_company: null,
    ie_scope: null,
    ie_year: null,
    emissions: null,
    ir_calculation_numerator: null,
    ie_company_value: null,
    ir_calculated_denominator: null,
    duration_id: null,
    month: null,
    year: null,
    type: null,
    memo_1: null,
    memo_2: null,
    country: null,
    layer_3: null,
    layer_4: null,
    layer_5: null,
    layer_6: null,
    ie_source: null,
    ir_investment_source: null,
  }
}
const calcEmissions = (irCalculationNumerator, irCalculatedDenominator, ieCompanyValue) => {
  if(irCalculatedDenominator === '0') {
    return '0.00'
  }
  if($_helper_isNumberType_bySetting(irCalculationNumerator) && $_helper_isNumberType_bySetting(irCalculatedDenominator) && $_helper_isNumberType_bySetting(ieCompanyValue)){
    const val_irCalculationNumerator = math.bignumber(formatNumberBySetting(irCalculationNumerator));
    const val_irCalculatedDenominator = math.bignumber(formatNumberBySetting(irCalculatedDenominator));
    const val_ieCompanyValue = math.bignumber(formatNumberBySetting(ieCompanyValue));
    return  formatBigNumber(math.evaluate(`${val_irCalculationNumerator.toString()} / ${val_irCalculatedDenominator.toString()} * ${val_ieCompanyValue.toString()}`), 50)
  }

  return '0.00';
}

const calcIrRatio = (irCalculationNumerator, irCalculatedDenominator) => {
  if(irCalculatedDenominator === '0') {
    return '0.00'
  }
  if($_helper_isNumberType_bySetting(irCalculationNumerator) && $_helper_isNumberType_bySetting(irCalculatedDenominator)){
    const val_irCalculationNumerator = math.bignumber(formatNumberBySetting(irCalculationNumerator));
    const val_irCalculatedDenominator = math.bignumber(formatNumberBySetting(irCalculatedDenominator));
    const valueCal = formatBigNumber(math.evaluate(`${val_irCalculationNumerator.toString()} / ${val_irCalculatedDenominator.toString()}`), 50);
    const value25Num = valueCal.includes('-') ? 26 : 25;
    return formatNumberRealNum(valueCal?.substring(0, value25Num))
  }

  return null;
}
const registerData = store.state.registerData;

const notNullList = [
  'organizational_division',
  'company_name',
  'ides_name_company',
  'ie_company_value',
  'ir_calculated_denominator',
  'ir_calculation_numerator',
  'ir_ratio',
  'ides_industry'
]

const max25Charactor = [
  'ie_company_value',
  'ie_year',
  'ir_calculated_denominator',
  'ir_calculation_numerator',
  'ir_ratio',
]

const max128Charactor = [
  // 'company_name',
  // 'business_name',
  'ides_name_company',
  'ides_industry',
  'ie_scope',
  'memo_1',
  'memo_2',
  'ie_source',
  'ir_investment_source',
]

const numberField = [
  'ie_company_value',
  'ie_year',
  'ir_calculated_denominator',
  'ir_calculation_numerator',
  'ir_ratio',
]

const validateNumberField = (input, propName) => {
  if (input) {
    if(!$_helper_isNumberType(input)){
      return MESSAGE_IS_NUMBER; 
    }
    //remove check negative number
    // if ($_helper_isNegativeNumber(input)) {
    //   return MESSAGE_POSITIVE_NUMBER;
    // }
    if (!validateInputIsYearGHG(input) && propName === 'ie_year') {
      return MESSAGE_INCORRECT_YEAR;
    }
    const numberValidate = formatValue(input).includes('-') ? 26 : 25
    if (formatValue(input).length > numberValidate) {
      return MESSAGE_MAX_20_CHARACTOR;
    }
  } else {
    return MESSAGE_NOT_NULL;
  }
  return null;
}

let isReverted = false;

const filterColumns = ['organizational_division', 'company_name', 'business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6']
let listMaxWidths = []

const autoBindingDbSource = dataBinding => {
  const { s, binding, row, cellData, currentItem } = dataBinding
  if (binding === 'ir_calculation_numerator') {
    s.deferUpdate(() => {
      currentItem['ir_ratio'] = calcIrRatio(cellData, currentItem.ir_calculated_denominator);
      currentItem['emissions'] = calcEmissions(cellData, currentItem.ir_calculated_denominator, currentItem.ie_company_value);
    })
  }

  if (binding === 'ir_calculated_denominator') {
    s.deferUpdate(() => {
      currentItem['ir_ratio'] = calcEmissions(currentItem.ir_calculation_numerator, cellData, currentItem.ie_company_value);
      currentItem['emissions'] = calcEmissions(currentItem.ir_calculation_numerator, cellData, currentItem.ie_company_value);
    })
  }

  if (binding === 'ie_company_value') {
    s.deferUpdate(() => {
      currentItem['emissions'] = calcEmissions(currentItem.ir_calculation_numerator, currentItem.ir_calculated_denominator, cellData);
    })
  }

  if (binding === 'ie_year') { 
    if(!validateInputIsYearGHG(cellData)) {
      s.deferUpdate(() => {
        currentItem['ie_year'] = null;
      })
    }
  }
}
let filterIndex = {};
const partern25 = {
  initializeGrid(dataProps) {
    const { flexgrid, itemCount, branchData = {}, getNewBbStore } =  dataProps
    let selectedFirst = null;
    let previousCellData = null;
    let checkFilterDataAfterPasted = {};
    flexgrid.scrollPositionChanged.addHandler(debounce((s, e) => {
      if (!store.state.registerData.isFullScreen) {
        return
      }

      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        s.deferUpdate(() => {
          const lastClientId = flexgrid.itemsSource.itemCount

          for (let index = 1; index <= itemCount; index++) {
            s.itemsSource.addNew(blankData(lastClientId + index));
          }

          s.itemsSource.commitNew();
          s.itemsSource.clearChanges();
        });
      }
    }, 100))

    document.addEventListener('keydown', (e) => {
      if (
        (e.metaKey || e.ctrlKey) &&
        [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW, KEYS_CODE.ENTER].includes(e.keyCode)
      ) {
        e.preventDefault();
      }
    });

    flexgrid.hostElement.addEventListener('keydown', (e) => {
      // console.log('keydown: ', e);
      if (e.metaKey || e.ctrlKey) {
        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col)
          flexgrid.selection = cellRange

          // re-select after add more
          setTimeout(() => {
            flexgrid.selection = cellRange
          }, 200);
        } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(0, currentSelection.col)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, 1)
          flexgrid.selection = cellRange
        }
      }
      if (e.keyCode === KEYS_CODE.ENTER) {
        if (flexgrid.selection.row === flexgrid.rows.length - 1) {
          const lastClientId = flexgrid.itemsSource.itemCount

          flexgrid.deferUpdate(() => {
            flexgrid.itemsSource.addNew(blankData(lastClientId + 1));

            flexgrid.itemsSource.commitNew();
            // flexgrid.itemsSource.clearChanges();
          });
        }
      }

      
    }, false)

    flexgrid.pasted.addHandler((s, e) => {
      const { col, col2, row, row2 } = e.range
      const view = s.collectionView
      const source = view.sourceCollection
      for (let colIndex = col; colIndex <= col2; colIndex++) {
        for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
          // add filter index after pasted
          if (filterColumns.includes(s.columns[colIndex].binding)) {
            if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
              addFilterByPasted(s, e, colIndex, rowIndex, filterIndex);
              if (typeof checkFilterDataAfterPasted[rowIndex] === 'undefined') {
                checkFilterDataAfterPasted[rowIndex] = {};
              }
              checkFilterDataAfterPasted[rowIndex] = source[rowIndex];
            }
          }
          if (['ir_calculation_numerator', 'ir_calculated_denominator', 'ie_company_value', 'ie_year'].includes(s.columns[colIndex].binding)) {
            const cellData = s.getCellData(rowIndex, colIndex, false)
            const currentItem = source[rowIndex] || {}
            const binding = s.columns[colIndex].binding
            const dataBinding = { s, binding, row: rowIndex, cellData, currentItem }
            //autoBindingDbSource(dataBinding)
          }
        }
      }
      if (Object.values(checkFilterDataAfterPasted).length > 0) {
        const errorMessage = checkFilterData(branchData.filterPatternList, store.state.registerData.layerFilter, checkFilterDataAfterPasted)
        store.dispatch('registerData/updateListErrorFilterDataMessage', errorMessage)
        checkFilterDataAfterPasted = {}
      }
    })

    flexgrid.cellEditEnded.addHandler((s, e) => {
      let column = s.columns[e.col];
      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[row] || {}
      const binding = column.binding
      const dataBinding = { s, binding, row, cellData, currentItem }
      const item = s.rows[e.row].dataItem;
      //autoBindingDbSource(dataBinding)

      if (filterColumns.includes(column.binding)) {
        handlerFilterData(s, e, branchData?.filterPatternList, filterIndex, selectedFirst, previousCellData, isReverted);
      }
    });

    flexgrid.beginningEdit.addHandler((s, e) => {
      let column = s.columns[e.col];
      let item = s.rows[e.row].dataItem;

      if (column.binding === 'ir_ratio' || column.binding === 'emissions') {
        e.cancel = true;
      }

      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[Number(item?.clientRowId) - 1 || row] || {}

      if (filterColumns.includes(column.binding)) {
        previousCellData = cellData;
        handlerAddFilterData(s, e, filterIndex, selectedFirst, store.state.registerData, branchData);
      }
    });
    
  },

  // TODO: [TRUNG] update name binding
  header(dataProps) {
    const { 
      registerData,
      listLayout,
      branchData,
      attachFilePopup,
      dbStore,
      uniqueOriginalDb,
      isPastingDbIdea = false
    } = dataProps

    let companyMap = new wjGrid.DataMap(branchData.company_name, 'value', 'value');
    companyMap.getDisplayValues = (dataItem) => {
      let validCompany = branchData?.company_name?.filter(
        (company) => company.organizational_division === dataItem?.organizational_division,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    companyMap.getDisplay = (dataItem) => {
      let validCompany = branchData?.company_name?.filter(
        (company) => company.organizational_division === dataItem,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    let businessMap = new wjGrid.DataMap(branchData.business_name, 'value', 'value');
    businessMap.getDisplayValues = (dataItem) => {
      let validBusiness = branchData?.business_name?.filter(
        (company) => company.company_name === dataItem?.company_name,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    businessMap.getDisplay = (dataItem, organizational_division) => {
      let validBusiness = branchData?.business_name?.filter(
        (business) => business.company_name === dataItem && business.organizational_division === organizational_division,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    const listDbAddNew = getListDbAddNew(dbStore.dbExternalAdded)
    const customDbListInPastingCell = isPastingDbIdea ? [listDbAddNew[listDbAddNew.length - 1]] : listDbAddNew
    const dbList = [ ...uniqueOriginalDb, ...customDbListInPastingCell ]

    return [
      {
        header: '連番',
        binding: 'id', // id
        minWidth: 40,
        maxWidth: 60,
        allowSorting: false,
        isReadOnly: true,
        visible: false,
        isRequired: false,
      },
      {
        header: i18n.t('56_pattern.table_organizational_division'),
        binding: 'organizational_division', // phan loai to chuc
        minWidth: 96,
        maxWidth: 400,
        allowResizing: true,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        cssClassAll: 'required-field',
        dataMap: new wjGrid.DataMap(registerData.listType, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: registerData.listType,
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      {
        header: i18n.t('56_pattern.table_company_name'),
        binding: 'company_name',
        minWidth: 85,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        cssClassAll: 'required-field',
        dataMap: companyMap,
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: branchData?.company_name,
          selectedValuePath: 'value',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      ...listLayout,
      {
        header: i18n.t('56_pattern.table_destination'),
        align: 'center',
        columns: [
          {
            header: i18n.t('56_pattern.table_ides_name_company'),
            binding: 'ides_name_company',
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            multiLine: true,
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_ides_industry'),
            binding: 'ides_industry', // todo: update to pull down
            minWidth: 70,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            multiLine: true,
            cssClassAll: 'required-field',
            dataMap: new wjGrid.DataMap(dbList, 'id', 'item_name'),
            editor: new AutoComplete(document.createElement('div'), {
            placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: dbList,
              selectedValuePath: 'id',
              displayMemberPath: 'item_name',
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1,
            }),
          },
        ],
      },
      {
        header: i18n.t('56_pattern.table_memo_1'),
        binding: 'memo_1',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        multiLine: true
      },
      {
        header: i18n.t('56_pattern.table_memo_2'),
        binding: 'memo_2',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        multiLine: true
      },
      {
        header: i18n.t('56_pattern.table_investment_and_financing'),
        align: 'center',
        columns: [
          {
            header: i18n.t('56_pattern.table_ie_company_value'),
            binding: 'ie_company_value',
            minWidth: 253,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_ie_year'),
            binding: 'ie_year',
            minWidth: 96,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_ie_scope'),
            binding: 'ie_scope',
            minWidth: 96,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            multiLine: true
          },
          {
            header: i18n.t('56_pattern.table_ie_source'), //source
            binding: 'ie_source',
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
          },
        ],
      },
      {
        header: i18n.t('56_pattern.table_investment_ratio'),
        align: 'center',
        columns: [
          {
            header: i18n.t('56_pattern.table_ir_calculated_denominator'),
            binding: 'ir_calculated_denominator',
            minWidth: 300,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_ir_calculation_numerator'),
            binding: 'ir_calculation_numerator',
            minWidth: 96,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_ir_ratio'),
            binding: 'ir_ratio',
            minWidth: 70,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_ir_investment_source'), //source
            binding: 'ir_investment_source',
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
          },
        ],
      },
      {
        header: i18n.t('56_pattern.table_emissions'),
        binding: 'emissions',
        cssClass: 'auto-increment',
        cssClassAll: 'emission',
        align: 'right',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
      },
      {
        header      : i18n.t('56_pattern.table_attach_file'),
        binding     : "attach_file",
        cssClass: 'auto-increment',
        minWidth    : getWidthOfAttachedButton(i18n.locale),
        maxWidth    : getWidthOfAttachedButton(i18n.locale),
        allowSorting: false,
        isRequired  : false,
        cssClassAll: "btn-db attach-file hide-filter",
        cellTemplate: (ctx, el) => {
          let text = i18n.t('56_pattern.table_attach_file');
          if (ctx.item?.evidence) {
            text = `<div class="file-attached-container">
              <img src="${require('@/assets/icons/file_attached.svg')}" alt="file attached icon" class="file_attached">
            </div>`
          }
          const button = CellMaker.makeButton({
            text: text,
            click: (e, context) => attachFilePopup(e, context)
          })
          return button(ctx, el);
        } 
      },
      {
        header: i18n.t('user_management.table_status'),
        binding: "status",
        minWidth: 100,
        width: 100,
        allowSorting: false,
        isRequired  : false,
        wordWrap: true,
        cssClassAll: 'status-field',
      }
    ];
  },

  handlerDataOnTable(dataProps) {
    const {
      dataTable,
      bindingLayout,
      isSaveTemplate,
      branchData,
      isGetBranchDetail,
      dbStore,
      itemAdded
    } = dataProps
    let databaseTypeObject = {};
    const wsuSource = dataTable.ides_industry;
    if (wsuSource) {
      databaseTypeObject = dbStore.getDbCustomizeOrDbMasterById[wsuSource] || {};
    } 

    dataTable.ir_ratio = calcIrRatio(dataTable.ir_calculation_numerator, dataTable.ir_calculated_denominator);
    if(!isGetBranchDetail) return
    const emissions = calcEmissions(dataTable.ir_calculation_numerator, dataTable.ir_calculated_denominator, dataTable.ie_company_value);
   // formatValue(emissions)?.substring(0, 20) = formatValue(emissions)?.substring(0, 20);
    // auto set data for layout
    const layoutsData = {}
    bindingLayout.forEach(key => {
      layoutsData[key] = dataTable[key] || null
    })
    const branchId = isGetBranchDetail ? getBranchId(branchData.filterPatternList, {
      organizational_division: dataTable.organizational_division,
      company_name: dataTable.company_name,
      ...layoutsData
    }) : {};

    if (typeof dataTable.evidence === 'string') { 
      dataTable.evidence = JSON.parse(dataTable.evidence);
    }
    let dataTableItemCustom = {
      id: dataTable.id,
      company_name: dataTable.company_name || null,
      organizational_division: dataTable.organizational_division || null,
      emissions: formatEmission25Number(emissions),
      ides_name_company: dataTable.ides_name_company?.trim() || null,
      ides_industry: dataTable.ides_industry || null,
      ie_year: formatNumberBySetting(dataTable.ie_year) === 0 ? '0' : formatNumberBySetting(dataTable.ie_year) || null,
      ie_scope: dataTable.ie_scope || null,
      ie_company_value: isSaveTemplate ? '' : formatNumberBySetting(dataTable.ie_company_value) === 0 ? '0' : formatNumberBySetting(dataTable.ie_company_value) || null,
      ir_calculated_denominator: isSaveTemplate ? '' : formatNumberBySetting(dataTable.ir_calculated_denominator) === 0 ? '0' : formatNumberBySetting(dataTable.ir_calculated_denominator) || null,
      ir_calculation_numerator: isSaveTemplate ? '' : formatNumberBySetting(dataTable.ir_calculation_numerator) === 0 ? '0' : formatNumberBySetting(dataTable.ir_calculation_numerator) || null,
      ie_source: dataTable.ie_source || null,
      ir_ratio: isSaveTemplate ? '' : formatEmission25Number(formatNumberBySetting(dataTable.ir_ratio)) === 0 ? '0' : formatEmission25Number(formatNumberBySetting(dataTable.ir_ratio)) || null,
      ir_investment_source: dataTable.ir_investment_source || null,
      contractor_id: store.state.userData.contractor, // from store
      month: dbStore.isFlagUpdateDataListEmission ? dataTable.month : store.state.registerData.month, // from store
      year: dbStore.isFlagUpdateDataListEmission ? dataTable.year : store.state.registerData.yearSelected,
      type: 1,
      memo_1: dataTable.memo_1 || null,
      memo_2: dataTable.memo_2 || null,
      ...layoutsData,
      branch_id: branchId?.id || null,
      db_master_id: databaseTypeObject.origin_id || null,
      evidence: dataTable.evidence || null,
      status: dataTable.status ? 1 : 0,
    };
      
    if (dataTable.evidence) {
      if (dataTable.is_primary_data) {
        delete dataTable.evidence.value;
      }
      else {
        dataTableItemCustom.evidence.value = dataTable[this.bindingValueColumn];
      }
    }
    
    return dataTableItemCustom;
  },

  addBlankItemsToView: (view, count) => {
    const lastClientId = view.itemCount
    for (let index = 1; index <= count; index++) {
      view.addNew(blankData(lastClientId + index));
    }

    view.commitNew();
    // view.clearChanges();
  },

  filterColumns: [
    'organizational_division',
    'company_name',
    'business_name',
    'ides_name_company',
    'ides_industry',
    'ie_year',
    'ie_scope',
    'ir_calculated_denominator',
    'ir_ratio',
  ],
  getError(item, propName, dbStore, validateErrorBtn = {}) {
    const {itemsInView = [], branchData = [], isCheckMappingFacility = false, itemsInViewError = []} = validateErrorBtn;

    if (notNullList.includes(propName)) {
      if (item[propName] === null || item[propName] === '' || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      }
    }

    if (numberField.includes(propName)) {
      if (!item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL
      } else {
        return validateNumberFieldBySetting(String(item[propName]), propName);
      }
    }

    if (max25Charactor.includes(propName)) {
      if (numberField.includes(propName)) {
        return validateNumberFieldBySetting(String(item[propName]), propName);
      }
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 25);
    }

    if (max128Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
    }

    if(isCheckMappingFacility && filterColumns.includes(propName)) {
      const layoutsData = {}
      itemsInView.forEach(key => {
        layoutsData[key] = item[key] || null
      })
      const branchId = getBranchId(branchData.filterPatternList, {
        organizational_division: item.organizational_division,
        company_name: item.company_name,
        ...layoutsData
      });

      if(!branchId) {
        return i18n.t('new_validate.error_mapping_layer', { listLayer: itemsInViewError.map(item => item)?.join('/') })
      }
    }
    return null
  },

  cellInputInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'ides_name_company',
      'ides_industry',
      'memo_1',
      'memo_2',
      'ie_company_value',
      'ie_year',
      'ie_scope',
      'ie_source',
      'ir_calculated_denominator',
      'ir_calculation_numerator',
      'ir_investment_source',
      'attach_file',
      ...bindingLayout
    ];
  },
  cellRequireInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'ides_name_company',
      'ides_industry',
      'memo_1',
      'memo_2',
      'ie_company_value',
      'ie_year',
      'ie_scope',
      'ie_source',
      'ir_calculated_denominator',
      'ir_calculation_numerator',
      'ir_investment_source',
      ...bindingLayout
    ];
  },
  autoFields: [
    'ides_industry',
  ],
  patternUrl: '/pattern-s3-c15/4',
  valueSource: 'ides_industry',

  calcEmissions(rowData) {
    return calcEmissions(rowData.ir_calculation_numerator, rowData.ir_calculated_denominator, rowData.ie_company_value)
  },

  handleResizeGrid(theGrid, listColumn, listWidth, callBack) {
    let paddingCell = 28

    listColumn.forEach((column, index) => {
      let width = listWidth[index]
      if(width < column.minWidth) {
        width = column.minWidth
        if(column.dataMap) {
          width = column.minWidth - paddingCell
        }
      } else if (width > column.maxWidth) {
        width = column.maxWidth
      }
      callBack(theGrid, index, column.dataMap ? width + paddingCell : width);
    })
  },

  handleResizeWhenCellEdit(theGrid, event, callBackGetWidth, callBackSetWidth) {
    const paddingCell = 28;
    const {row, col} = event.range;
    const cellData = theGrid.getCellData(row, col, false);
    if(!cellData) return 

    const isDataMapCell = theGrid.columnHeaders.columns[col]?.dataMap;
    let widthCellEditing = isDataMapCell ? callBackGetWidth(cellData) + paddingCell : callBackGetWidth(cellData);

    if(listMaxWidths[col] < widthCellEditing) {
      callBackSetWidth(theGrid, col, widthCellEditing);
      listMaxWidths[col] = widthCellEditing
    }
  },

  handleResizeWhenPasted(theGrid, event, columns, callBackGetWidth, callBackSetWidth) {
    const {row, row2} = event.range;
    const source = theGrid.collectionView.sourceCollection.slice(row, row2);

    columns.forEach((column, indexColumn) => {
      let indexMaxLengthOfColumn = source.reduce((indexMaxLengthOfColumn, rowData, index) => {
        let dataLength = rowData?.[column.binding]?.toString()?.length;
        let dataLengthInView = source[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length
        if(column.binding === 'organizational_division' && rowData?.organizational_division) {
          const organizationList = ['単体', '子会社', '関連会社', 'その他関連会社']
           const realValOrganization = rowData?.organizational_division ? organizationList[rowData?.organizational_division - 1] : '単体'
          const realMaxValOrganization = curentDataInView?.organizational_division ? organizationList[curentDataInView?.organizational_division - 1] : '単体'
          dataLength = realValOrganization.length
          dataLengthInView = realMaxValOrganization.length
        }
        if(dataLength && !dataLengthInView) {
          return index
        }
        return dataLength > dataLengthInView ? index : indexMaxLengthOfColumn
      }, 0);

      const dataMaxLengthInview = source[indexMaxLengthOfColumn];
      let contentMaxWidth = dataMaxLengthInview?.[column.binding]
      const widthOfCellPasteingMaxlengh = callBackGetWidth(contentMaxWidth)

      if(listMaxWidths[indexColumn] < widthOfCellPasteingMaxlengh) {
        callBackSetWidth(theGrid, indexColumn, widthOfCellPasteingMaxlengh)
        listMaxWidths[indexColumn] = widthOfCellPasteingMaxlengh
      }
    })
  },
  bindingValueColumn: 'ie_company_value',

  setFilterIndex(filterIndexValue) {
    filterIndex = filterIndexValue;
  },
  updateFilterIndex(filterIndexValue) {
    filterIndex = {...filterIndex, ...filterIndexValue }
  },
  columnsCalcEmission: ['ir_calculation_numerator', 'ir_calculated_denominator', 'ie_company_value'],
  getAutoBindingDbSource(dataProps) {
    return autoBindingDbSource(dataProps);
  }
};

export default partern25;
